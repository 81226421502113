'use strict';

(function ($, DUNLOP) {
	var a = 'is-active';

	var floatNav = function floatNav() {
		var targetTop = void 0;
		var $float = void 0;

		if ($('.idx-school-nav').length) {
			targetTop = $('.idx-school-nav').eq(0).offset().top + $('.idx-school-nav').eq(0).height();
			$float = $('.idx-school-nav_float');
		} else if ($('.shop-detail-nav').length) {
			targetTop = $('.shop-detail-nav').eq(0).offset().top + $('.shop-detail-nav').eq(0).height();
			$float = $('.shop-detail-nav_float');
		}
		if (targetTop) {
			$(window).scroll(function (e) {
				var thisScroll = $(e.currentTarget).scrollTop();
				if (targetTop < thisScroll) {
					$float.addClass(a);
				} else {
					$float.removeClass(a);
				}
			});
		}
	};
	var setShareLinks = function setShareLinks() {
		$('.js-share-item').each(function (idx, ele) {
			var url = encodeURIComponent(document.URL);
			var title = encodeURIComponent(document.title);
			var $this = $(ele);
			var href = $this.attr('href').replace(/#link/ig, url).replace(/#text/ig, title);

			$this.attr('href', href);
		});
	};
	var balloonChips = function balloonChips() {
		$('.mod-balloon').each(function (idx, ele) {
			$(ele).find('.mod-balloon-switch').on('click', function (e) {
				$(ele).find('.mod-balloon-inner').toggleClass(a);
				e.preventDefault();

				if (DUNLOP.va.window.width < 768 && $(ele).find('.mod-balloon-inner2').length) {
					var pos = $(ele).offset().top - $(ele).parents('.mod-searchForm-inner').offset().top + 10;
					$(ele).find('.mod-balloon-inner').css('top', pos);
				}

				$(ele).find('.icon-close').on('click', function () {
					$(ele).find('.mod-balloon-inner').removeClass(a);
				});
			});
		});
	};
	var mainVisSlider = function mainVisSlider() {
		var arrowPrev = '<button type="button" class="slick-prev"><i class="icon icon-arrow_left"></button>';
		var arrowNext = '<button type="button" class="slick-next"><i class="icon icon-arrow_right"></button>';
		var flgPC = void 0,
		    flgSP = void 0;
		var setSlick = function setSlick() {
			$('.mod-mainVisSlider').slick({
				infinite: true,
				speed: 1000,
				autoplay: true,
				autoplaySpeed: 4000,
				slidesToShow: 1,
				arrows: true,
				dots: true,
				prevArrow: arrowPrev,
				nextArrow: arrowNext
			});
		};
		var setSlickPC = function setSlickPC() {
			flgPC = true;
			$('.mod-mainVisSlider_pc').slick({
				infinite: true,
				speed: 1000,
				autoplay: true,
				autoplaySpeed: 4000,
				slidesToShow: 1,
				arrows: true,
				dots: true,
				prevArrow: arrowPrev,
				nextArrow: arrowNext
			});
		};
		var setSlickSP = function setSlickSP() {
			flgSP = true;
			$('.mod-mainVisSlider_sp').slick({
				infinite: true,
				speed: 1000,
				autoplay: true,
				autoplaySpeed: 4000,
				slidesToShow: 1,
				arrows: true,
				dots: true,
				prevArrow: arrowPrev,
				nextArrow: arrowNext
			});
		};

		if ($('.mod-mainVisSlider_pc').length) {
			if (DUNLOP.va.window.width >= 768) {
				setSlickPC();
			} else {
				setSlickSP();
			}
		} else {
			setSlick();
		}
		floatNav();

		var thisWidth = DUNLOP.va.window.width;
		$(window).resize(function () {
			if (thisWidth < 768 && $(window).width() >= 768 && !flgPC) {
				setSlickPC();
			} else if (thisWidth >= 768 && $(window).width() < 768 && !flgSP) {
				setSlickSP();
			}
		});
	};
	var setTicker = function setTicker() {
		var cutTickerText = function cutTickerText(_cnt) {
			$('.mod-ticker-item').find('.mod-ticker-title').each(function (idx, ele) {
				var rawText = $(ele).text();
				if (rawText.length > _cnt) {
					$(ele).text(rawText.substring(0, _cnt) + '...');
				}
			});
		};
		if (DUNLOP.va.window.width >= 1024) {
			cutTickerText(50);
		} else if (DUNLOP.va.window.width < 1024 && DUNLOP.va.window.width >= 768) {
			cutTickerText(35);
		} else if (DUNLOP.va.window.width < 768) {
			cutTickerText(20);
		}
		var $setElm = $('.js-ticker');
		var effectSpeed = 1000;
		var switchDelay = 5000;
		var easing = 'swing';

		if ($setElm.find('.mod-ticker-item').length > 1) {
			$setElm.each(function (idx, ele) {
				// const effectFilter = $(ele).attr('rel');
				var $targetObj = $(ele);
				var $targetUl = $targetObj.children('ul');
				var $targetLi = $targetObj.find('li');
				var $setList = $targetObj.find('li:first');
				var ulWidth = $targetUl.width();
				// const listHeight = $targetLi.height();
				// $targetObj.css({ height:(listHeight) });
				$targetLi.css({ top: '0', left: '0', position: 'absolute' });
				var liCont = $targetLi.length;

				$setList.css({ left: ulWidth, display: 'block', opacity: '0', zIndex: '98' }).stop().animate({ left: '0', opacity: '1' }, effectSpeed, easing).addClass('showlist');
				if (liCont > 1) {
					setInterval(function () {
						var $activeShow = $targetObj.find('.showlist');
						$activeShow.animate({ left: -ulWidth, opacity: '0' }, effectSpeed, easing).next().css({ left: ulWidth, display: 'block', opacity: '0', zIndex: '99' }).animate({ left: '0', opacity: '1' }, effectSpeed, easing).addClass('showlist').end().appendTo($targetUl).css({ zIndex: '98' }).removeClass('showlist');
					}, switchDelay);
				}
			});
		} else {
			$setElm.find('.mod-ticker-item').css('opacity', '1').css('display', 'block');
		}
	};
	var setSeasonClass = function setSeasonClass() {
		// 404ページなど、ルート設定ができないページ（＆ローカル）用の季節class付与処理
		var $pagetop = $('#pagetop');

		if (!$pagetop.hasClass('spring') && !$pagetop.hasClass('summer') && !$pagetop.hasClass('autumn') && !$pagetop.hasClass('winter')) {
			var thisDate = new Date();
			var thisMonth = thisDate.getMonth() + 1;
			if (thisMonth === 3 || thisMonth === 4 || thisMonth === 5) {
				$pagetop.addClass('spring');
			} else if (thisMonth === 6 || thisMonth === 7 || thisMonth === 8) {
				$pagetop.addClass('summer');
			} else if (thisMonth === 9 || thisMonth === 10 || thisMonth === 11) {
				$pagetop.addClass('autumn');
			} else if (thisMonth === 12 || thisMonth === 1 || thisMonth === 2) {
				$pagetop.addClass('winter');
			}
		}
	};
	var deleteEmptyTags = function deleteEmptyTags() {
		// ニュース系の空のタグを削除する
		if ($('.cf_n_tags').length) {
			$('.cf_n_tags').each(function (idx, ele) {
				if (!$(ele).find('span')[0]) {
					$(ele).remove();
				}
			});
		}
	};

	setShareLinks();
	balloonChips();
	setSeasonClass();

	if ($('.mod-mainVisSlider').length) {
		mainVisSlider();
	} else {
		floatNav();
	}

	if ($('.mod-ticker').length) {
		setTicker();
	}

	deleteEmptyTags();
})(window.jQuery, window.DUNLOP);