'use strict';

window.console || (window.console = {
	log: function log() {}
});
var DUNLOP = window.DUNLOP || null;
var cssua = window.cssua || null;

(function ($) {
	var siteName = 'sports.dunlop';
	var a = 'is-active';
	var v = 'is-visible';
	var h = 'is-hidden';
	var f = 'is-fixed';
	var slideSpeed = '400';

	DUNLOP = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.0\\.|192\\.168\\.11\\.|192\\.168\\.1\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (DUNLOP.va.window.width < DUNLOP.va.device.sp) {
						pos -= 65;
					} else {
						pos -= 90;
					}
				}
				$('.header').removeClass(a);
				$('.header-overlay').remove();
				$('.header-menuBlock').removeClass(v);
				$('.header-menuBlock').removeClass(a);
				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash) {
				$(window).on('load', function () {
					$('html, body').css('display', 'none');

					setTimeout(function () {
						$('html, body').css('display', 'block');

						var _hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;

						if (DUNLOP.va.window.width <= DUNLOP.va.device.desktop) {
							pos -= 65;
						} else {
							pos -= 90;
						}
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 100);
				});
			}
		},
		headerMenu: function headerMenu() {
			var $header = $('.header');
			var $menuBtn = $('.header-menu');
			var $menuBlock = $('.header-menuBlock');
			var $search = $('.header-search');
			var $searchBtn = $('.header-search-icon');
			var $searchOverlay = $('.header-search-overlay');

			var menuClose = function menuClose() {
				$header.removeClass(a);
				$('.header-overlay').remove();
				$menuBlock.removeClass(v);
				setTimeout(function () {
					$menuBlock.removeClass(a);
				}, 300);
			};
			var menuOpen = function menuOpen() {
				var overlayHTML = '<div class="header-overlay"></div>';
				$header.append(overlayHTML);
				$header.addClass(a);
				$menuBlock.addClass(a);
				setTimeout(function () {
					$menuBlock.addClass(v);
				}, 100);

				$('.header-overlay').on('click', function () {
					menuClose();
				});

				if ($search.hasClass(a)) {
					$searchBtn.removeClass(a);
					$search.removeClass(v);
					$searchOverlay.removeClass(v);
					setTimeout(function () {
						$search.removeClass(a);
						$searchOverlay.removeClass(a);
					}, 400);
				}
			};
			$menuBtn.on('click', function () {
				if ($menuBlock.hasClass(v)) {
					menuClose();
				} else {
					menuOpen();
				}
			});

			// ポータル用
			$('.menu-list-item-about').on('click', function () {
				menuClose();
			});

			// サイト内検索
			$(document).on('click', '.header-search-icon, .header-search-overlay', function () {
				if ($header.hasClass(a)) {
					$header.removeClass(a);
					$menuBlock.removeClass(v);
					setTimeout(function () {
						$menuBlock.removeClass(a);
					}, 400);
				}

				if ($search.hasClass(a)) {
					$searchBtn.removeClass(a);
					$search.removeClass(v);
					$searchOverlay.removeClass(v);
					setTimeout(function () {
						$search.removeClass(a);
						$searchOverlay.removeClass(a);
					}, 400);
				} else {
					$searchBtn.addClass(a);
					$search.addClass(a);
					$searchOverlay.addClass(a);
					setTimeout(function () {
						$search.addClass(v);
						$searchOverlay.addClass(v);
					});
				}
			});
		},
		footerMenu: function footerMenu() {
			var fHead = $('.footer-head');
			var fBody = $('.footer-body');

			if (DUNLOP.va.window.width <= 1024) {
				fHead.on('click', function (e) {
					$(e.currentTarget).toggleClass(a);
					$(e.currentTarget).next(fBody).slideToggle();
				});
			}
		},
		tabSwitch: function tabSwitch() {
			var $tab = $('.js-tab');
			var $content = $('.js-tab-content');

			$tab.on('click', function (e) {
				if (!$(e.currentTarget).hasClass(a)) {
					$tab.each(function (idx, ele) {
						$(ele).removeClass(a).addClass(h);
					});
					$(e.currentTarget).addClass(a).removeClass(h);

					var $target = $(e.currentTarget).attr('data-switch');
					var $targetSwitch = $('[data-switch=' + $target + ']');
					$targetSwitch.addClass(a);

					$content.each(function (idx, ele) {
						$(ele).removeClass(a).removeClass(v);
					});

					var $switch = $('[data-target=' + $(e.currentTarget).attr('data-switch') + ']');
					$switch.addClass(a);
					setTimeout(function () {
						$switch.addClass(v);
					}, '200');
				}
			});

			// 下のタブをクリックしたときは上まで戻る
			$('.mod-tab01_bottom .js-tab').on('click', function () {
				var pos = $('.mod-tab01').offset().top;
				if (DUNLOP.va.window.width < DUNLOP.va.device.sp) {
					pos -= 65;
				} else {
					pos -= 90;
				}
				$('html,body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
			});
		},
		tabCurrent: function tabCurrent() {
			var $map = DUNLOP.va.pathname;

			if ($('.mod-tab02').length) {
				$('.mod-tab02-a*[data-switch="top"]').addClass(a);
				$('.mod-tab02-a').each(function () {
					var target = $(this).attr('data-switch');
					if ($map.indexOf(target) !== -1) {
						$(this).addClass(a);
						$('.mod-tab02-a*[data-switch="top"]').removeClass(a);
					}
				});
			}
		},
		modModal: function modModal() {
			if ($('.js-modal').length) {
				var $modal = $('.js-modal');
				var $modalBtn = $('.js-modal-btn');
				var $modalContent = $('.js-modal-content');
				var scrollTopNow = void 0;

				$modalBtn.on('click', function (e) {
					var $this = $(e.currentTarget);
					var modalImg = void 0;
					if ($this.find('.js-modal-img').length) {
						modalImg = $this.find('.js-modal-img').attr('src');
					} else if ($this.attr('href') !== '') {
						modalImg = $this.attr('href');
					} else if ($this.next('img').length) {
						modalImg = $this.next('img').attr('src');
					}
					$modalContent.html('<img src="' + modalImg + '"><i class="icon icon-close"></i>');

					$modal.addClass(a);
					scrollTopNow = $(window).scrollTop();
					$('.container').css({
						position: 'fixed',
						width: '100%',
						top: -1 * scrollTopNow,
						left: 0
					});
					$('body').addClass(f);
					e.preventDefault();
				});

				$modalContent.on('click', $('.js-modal-content i'), function (e) {
					$modal.removeClass(a);
					$('.container').css({
						position: 'static'
					});
					$('body').removeClass(f);
					$(window).scrollTop(scrollTopNow);
					e.preventDefault();
				});

				$('.js-modal-overlay').on('click', function (e) {
					$modal.removeClass(a);
					$('.container').css({
						position: 'static'
					});
					$('body').removeClass(f);
					$(window).scrollTop(scrollTopNow);
					e.preventDefault();
				});
			}
		},
		navCurrent: function navCurrent() {
			$('.faq-nav').find('a').each(function (idx, ele) {
				if (DUNLOP.va.pathname.indexOf($(ele).attr('href')) !== -1) {
					$(ele).addClass(a);
				}
			});
		},
		siteSearch: function siteSearch() {
			(function () {
				var s = document.createElement('script');
				s.src = '/common/js/siteSearch.js';
				document.getElementsByTagName('body')[0].appendChild(s);
			})();
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.headerMenu();
			_this.footerMenu();
			_this.tabSwitch();
			_this.tabCurrent();
			_this.modModal();
			_this.siteSearch();
			if (DUNLOP.va.pathname.indexOf('/faq/') !== -1) {
				_this.navCurrent();
			}
		}
	};

	$(function () {
		return DUNLOP.localDecision() ? DUNLOP.localLoading() : DUNLOP.loadDelayScript();
	});
})(window.jQuery);